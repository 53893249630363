import React from "react";
import styled from "styled-components";
import left from "../../../assets/images/memoji/left.png";
import right from "../../../assets/images/memoji/right.png";
import sidelook from "../../../assets/images/memoji/sidelook.png";
import smile from "../../../assets/images/memoji/smile.png";

const Experience = (props) => {
  return (
    <Div>
      <div className="hello">
        <h1>
          Hello{" "}
          <span role="img" aria-label="wave" aria-labelledby="hello">
            👋
          </span>
        </h1>
      </div>

      <div className="intro">
        <div className="avatar">
          <img className="smile" src={smile} alt="Menawer" />
          <img className="left" src={left} alt="Menawer" />
          <img className="right" src={right} alt="Menawer" />
          <img className="sidelook" src={sidelook} alt="Menawer" />
        </div>
        <div>
          <h2>
            My name is Menawer and I am a{" "}
            <span className="background-yellow">software developer</span> by
            hobby & luckily do the same as a full time job. In my free time I
            try to{" "}
            <span role="img" aria-label="pain" aria-labelledby="intro">
              🎨
            </span>{" "}
            and i'm not that good at it, but hey i am better at{" "}
            <span role="img" aria-label="guitar" aria-labelledby="intro">
              🎸
            </span>{" "}
            and
            <span role="img" aria-label="basketball" aria-labelledby="intro">
              🏀
            </span>
            .
            <br />
          </h2>

          <h3>
            beside that, I sometimes create some useful software development
            posts on instagram, mostly web related.
          </h3>

          <div className="social">
            <div className="pointer">
              <span role="img" aria-label="point" aria-labelledby="hello">
                👉
              </span>
            </div>
            <a target="_blank" href="https://www.instagram.com/eng_menawer">
              Instagram
            </a>
          </div>

          <div className="social">
            <div className="pointer">
              <span role="img" aria-label="point" aria-labelledby="hello">
                👉
              </span>
            </div>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCJ2IT7zJvY2vR7Xcf4lJlcA"
            >
              Youtube
            </a>
          </div>

          <div className="social">
            <div className="pointer">
              <span role="img" aria-label="point" aria-labelledby="hello">
                👉
              </span>
            </div>
            <a target="_blank" href="https://www.github.com/careless10">
              Github
            </a>
          </div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  .hello {
    margin-bottom: 80px;
  }

  .background-yellow {
    background-color: #f8e71c;
  }

  .intro {
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 670px;
    }
  }

  .social {
    position: relative;
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    font-weight: bold;

    .pointer {
      position: absolute;
      left: 0;
      transition: all 0.4s ease-in-out;
    }

    &:hover {
      .pointer {
        left: 40px;
      }
    }

    a {
      margin-left: 64px;
    }

    a:hover {
      margin-left: ;
    }
  }

  .avatar {
    overflow: hidden;
    background-color: white;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    margin-bottom: 32px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.08);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      position: absolute;
      height: 80px;
      width: 60px;
      opacity: 0;
    }

    .left {
      animation: show-face 1s linear 0s 1 alternate;
    }
    .right {
      animation: show-face 1s linear 1s 1 alternate;
    }
    .sidelook {
      animation: show-face 1s linear 2s 1 alternate;
    }
    .smile {
      animation: show-face 1s linear 3s 1 normal;
      animation-fill-mode: forwards;
    }

    @keyframes show-face {
      0% {
        opacity: 0;
      }
      70% {
        opacity: 1;
      }
      100% {
        opacity: 1;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
  }

  @media only screen and (max-width: 768px) {
    .hello {
      margin-bottom: 32px;
    }
    .intro {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .social a {
      margin-left: 32px;
    }
  }
`;

export default Experience;
