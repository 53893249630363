import React, { useState } from 'react';
import MCQTest from './MCQTest';
import ChapterSelection from './ChapterSelection';
import './MCQ.css';
import chapters from './questions';

function MCQ() {
    const [selectedChapter, setSelectedChapter] = useState(null);

    const handleChapterSelect = (chapterKey) => {
        const selected = chapters.find(chapter => chapter.key === chapterKey);
        setSelectedChapter(selected.questions);
    };

    const handleBackToChapterSelection = () => {
        setSelectedChapter(null);
    };

    const hideComponent = !window.location.href.includes('/law');

    if (hideComponent) {
        return null; // Return null to hide the component
    }

    return (
        <div className="card" data-aos="fade-in">
            <div className="MCQ">
                <h1>Law & Ethics Test</h1>
                {selectedChapter ? (
                    <MCQTest questions={selectedChapter} onBack={handleBackToChapterSelection} />
                ) : (
                    <ChapterSelection chapters={chapters} onChapterSelect={handleChapterSelect} />
                )}
            </div>
        </div>
    );
}

export default MCQ;
