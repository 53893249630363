import React from "react";
import styled from "styled-components";
import css from "../../../assets/images/css.png";
import javascript from "../../../assets/images/javascript.png";
import adonisjs from "../../../assets/images/adonisjs.png";
import react from "../../../assets/images/react.png";

const Languages = (props) => {
  return (
    <Div>
      <div className="hello">
        <h1>Languages</h1>
      </div>

      <div className="intro">
        <div>
          <h2>
            Normally I prefer communicating with people in english or arabic but
            I can also start a conversation with the below{" "}
            <span role="img" aria-label="grinning" aria-labelledby="intro">
              😅
            </span>
            <br />
          </h2>
        </div>
      </div>

      <div className="languages">
        <div className="language">
          <img src={javascript} alt="javascript" />
          <div>
            <h3>Javascript</h3>
            <p>
              Javascript is an awesome language, it's covering more and more
              platform.
            </p>
          </div>
        </div>
        <div className="language">
          <img src={react} alt="reactjs" />
          <div>
            <h3>ReactJS</h3>
            <p>CSR & SSR, with CRA and Nextjs depending on the requirements.</p>
          </div>
        </div>
        <div className="language">
          <img src={css} alt="css" />
          <div>
            <h3>CSS3</h3>
            <p>
              The beauty of this language that, many think its easy. Yet it’s a
              trap!
            </p>
          </div>
        </div>
        <div className="language">
          <img src={adonisjs} alt="adonisjs" />
          <div>
            <h3>Adonis</h3>
            <p>
              Moved from Laravel to Adonis, which is a javascript framework,
              that follows Laravel elegant standards
            </p>
          </div>
        </div>
      </div>
    </Div>
  );
};

const Div = styled.div`
  .intro {
    display: flex;
    justify-content: space-between;
    > div {
      max-width: 670px;
    }
  }

  .languages {
    display: flex;
    flex-wrap: wrap;

    .language {
      margin-bottom: 48px;
      display: flex;
      h3 {
        font-weight: bold;
        margin: 0 0 8px 0;
      }
      p {
        max-width: 268px;
      }
      img {
        margin-right: 24px;
        height: 80px;
        width: 80px;
      }
    }
  }
`;

export default Languages;
