import React from 'react';

const ChapterSelection = ({ chapters, onChapterSelect }) => {
    return (
        <div className="chapter-selection">
            <h2>Select a Chapter</h2>
            {chapters.map(chapter => (
                <button key={chapter.key} onClick={() => onChapterSelect(chapter.key)}>
                    {chapter.name}
                </button>
            ))}
        </div>
    );
};

export default ChapterSelection;
