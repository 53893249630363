const chapters = [
    {
        key: 'acct510',
        name: 'ACCT 510: Exam Review',
        questions: [
            {
                question: "Cost objects include:",
                options: [
                    "Products",
                    "Customers",
                    "Departments",
                    "All of these answers are correct."
                ],
                answer: "All of these answers are correct."
            },
            {
                question: "In order to make decisions managers need to know:",
                options: [
                    "Actual costs",
                    "Budgeted costs",
                    "Both costs",
                    "Neither cost"
                ],
                answer: "Both costs"
            },
            {
                question: "Budgeted costs are:",
                options: [
                    "The costs incurred this year",
                    "The costs incurred last year",
                    "Planned or forecasted costs",
                    "Competitor's costs"
                ],
                answer: "Planned or forecasted costs"
            },
            {
                question: "Which of the following does NOT affect the direct/indirect classification of a cost?",
                options: [
                    "The level of budgeted profit for the next year",
                    "The materiality of the cost in question",
                    "Available technology to gather information about the cost",
                    "The design of the operation"
                ],
                answer: "The level of budgeted profit for the next year"
            },
            {
                question: "Which of the following statements about the direct/indirect cost classification is NOT true?",
                options: [
                    "Direct costs are always traced.",
                    "Direct costs are always allocated.",
                    "The design of operations affects the direct/indirect classification.",
                    "The direct/indirect classification depends on the choice of cost object."
                ],
                answer: "Direct costs are always allocated."
            },
            {
                question: "Cost allocation is:",
                options: [
                    "The process of tracking both direct and indirect costs associated with a cost object",
                    "The process of determining the actual cost of the cost object",
                    "The assignment of indirect costs to the chosen cost object",
                    "A function of cost tracing"
                ],
                answer: "The assignment of indirect costs to the chosen cost object"
            },
            {
                question: "The determination of a cost as either direct or indirect depends upon the:",
                options: [
                    "Accounting system",
                    "Allocation system",
                    "Cost tracing system",
                    "Cost object chosen"
                ],
                answer: "Cost tracing system"
            },
            {
                question: "A manufacturing plant produces two product lines: football equipment and hockey equipment. Direct costs for the football equipment line are the:",
                options: [
                    "Beverages provided daily in the plant break room",
                    "Monthly lease payments for a specialized piece of equipment needed to manufacture the football helmet",
                    "Salaries of the clerical staff that work in the company administrative offices",
                    "Utilities paid for the manufacturing plant"
                ],
                answer: "Monthly lease payments for a specialized piece of equipment needed to manufacture the football helmet"
            },
            {
                question: "A manufacturing plant produces two product lines: football equipment and hockey equipment. An indirect cost for the hockey equipment line is the:",
                options: [
                    "Material used to make the hockey sticks",
                    "Labor to bind the shaft to the blade of the hockey stick",
                    "Shift supervisor for the hockey line",
                    "Plant supervisor"
                ],
                answer: "Plant supervisor"
            },
            {
                question: "Indirect manufacturing costs:",
                options: [
                    "Can be traced to the product that created the costs",
                    "Can be easily identified with the cost object",
                    "Generally include the cost of material and the cost of labor",
                    "May include both variable and fixed costs"
                ],
                answer: "May include both variable and fixed costs"
            },
            {
                question: "All of the following are true EXCEPT that indirect costs:",
                options: [
                    "May be included in prime costs",
                    "Are not easily traced to products or services",
                    "Vary with the selection of the cost object",
                    "May be included in manufacturing overhead"
                ],
                answer: "May be included in prime costs"
            },
            {
                question: "Which statement is TRUE?",
                options: [
                    "All variable costs are direct costs.",
                    "Because of a cost-benefit tradeoff some direct costs may be treated as indirect costs.",
                    "All fixed costs are indirect costs.",
                    "All direct costs are variable costs."
                ],
                answer: "Because of a cost-benefit tradeoff some direct costs may be treated as indirect costs."
            },
            {
                question: "Cost behavior refers to:",
                options: [
                    "How costs react to a change in the level of activity",
                    "Whether a cost is incurred in a manufacturing merchandising or service company",
                    "Classifying costs as either inventoriable or period costs",
                    "Whether a particular expense has been ethically incurred"
                ],
                answer: "How costs react to a change in the level of activity"
            },
            {
                question: "At a plant where a union agreement sets annual salaries and conditions annual labor costs usually:",
                options: [
                    "Are considered a variable cost",
                    "Are considered a fixed cost",
                    "Depend on the scheduling of floor workers",
                    "Depend on the scheduling of production runs"
                ],
                answer: "Are considered a fixed cost"
            },
            {
                question: "Which of the following is a fixed cost for an automobile manufacturing plant?",
                options: [
                    "Administrative salaries",
                    "Electricity used by assembly-line machines",
                    "Sales commissions",
                    "Windows for each car produced"
                ],
                answer: "Administrative salaries"
            },
            {
                question: "When 10000 units are produced variable costs are $6 per unit. Therefore when 20000 units are produced:",
                options: [
                    "Variable costs will total $120000",
                    "Variable costs will total $60000",
                    "Variable unit costs will increase to $12 per unit",
                    "Variable unit costs will decrease to $3 per unit"
                ],
                answer: "Variable costs will total $120000"
            },
            {
                question: "Christi Manufacturing provided the following information for last month:\n\n\tSales\t\t$10000\n\tVariable costs\t3000\n\tFixed costs\t5000\n\tOperating income\t$2000\n\nIf sales double next month what is the projected operating income?",
                options: [
                    "$4000",
                    "$7000",
                    "$9000",
                    "$12000"
                ],
                answer: "$9000"
            },
            {
                question: "Kym Manufacturing provided the following information for last month:\n\n\tSales\t\t$12000\n\tVariable costs\t4000\n\tFixed costs\t1000\n\tOperating income\t$7000\n\nIf sales double next month what is the projected operating income?",
                options: [
                    "$14000",
                    "$15000",
                    "$18000",
                    "$19000"
                ],
                answer: "$15000"
            },
            {
                question: "Wheel and Tire Manufacturing currently produces 1000 tires per month. The following per unit data apply for sales to regular customers:\n\n\tDirect materials\t\t\t$20\n\tDirect manufacturing labor\t3\n\tVariable manufacturing overhead\t6\n\tFixed manufacturing overhead\t10\n\tTotal manufacturing costs\t$39\n\nThe plant has capacity for 3000 tires and is considering expanding production to 2000 tires. What is the total cost of producing 2000 tires?",
                options: [
                    "$39000",
                    "$78000",
                    "$68000",
                    "$62000"
                ],
                answer: "$68000"
            },
            {
                question: "Tire and Spoke Manufacturing currently produces 1000 bicycles per month. The following per unit data apply for sales to regular customers:\n\n\tDirect materials\t\t\t$50\n\tDirect manufacturing labor\t5\n\tVariable manufacturing overhead\t14\n\tFixed manufacturing overhead\t10\n\tTotal manufacturing costs\t$79\n\nThe plant has capacity for 3000 bicycles and is considering expanding production to 2000 bicycles. What is the per unit cost of producing 2000 bicycles?",
                options: [
                    "$79 per unit",
                    "$158 per unit",
                    "$74 per unit",
                    "$134 per unit"
                ],
                answer: "$74 per unit"
            },
            {
                question: "Axle and Wheel Manufacturing currently produces 1000 axles per month. The following per unit data apply for sales to regular customers:\n\tDirect materials\t\t\t$30\n\tDirect manufacturing labor\t5\n\tVariable manufacturing overhead\t10\n\tFixed manufacturing overhead\t40\n\tTotal manufacturing costs\t$85\n\nThe plant has capacity for 2000 axles and is considering expanding production to 1500 axles. What is the total cost of producing 1500 axles?",
                options: [
                    "$85000",
                    "$170000",
                    "$107500",
                    "$102500"
                ],
                answer: "$107500"
            },
            {
                question: "Based on the previous information what is the per unit cost when producing 1500 axles?",
                options: [
                    "$71.67",
                    "$107.50",
                    "$85.00",
                    "$170.00"
                ],
                answer: "$71.67"
            },
            {
                question: "Pederson Company reported the following:\n\n\tManufacturing costs\t$2000000\n\tUnits manufactured\t50000\n\tUnits sold\t47000 units sold for $75 per unit\n\tBeginning inventory\t0 units\n\nWhat is the average manufacturing cost per unit?",
                options: [
                    "$40.00",
                    "$42.55",
                    "$0.025",
                    "$75.00"
                ],
                answer: "$40.00"
            },
            {
                question: "Based on the previous information what is the amount of ending finished goods inventory?",
                options: [
                    "$1880000",
                    "$120000",
                    "$225000",
                    "$105000"
                ],
                answer: "$120000"
            },
            {
                question: "Job costing:",
                options: [
                    "Can only be used in manufacturing",
                    "Records the flow of costs for each customer",
                    "Allocates an equal amount of cost to each unit made during a time period",
                    "Is commonly used when each unit of output is identical"
                ],
                answer: "Records the flow of costs for each customer"
            },
            {
                question: "Job-costing may only be used by:",
                options: [
                    "Service companies",
                    "Merchandising companies",
                    "Manufacturing companies",
                    "All of these may use job-costing."
                ],
                answer: "All of these may use job-costing."
            },
            {
                question: "Many large companies which have multiple production methods and processes have hybrid costing systems that are:",
                options: [
                    "Job-costing",
                    "Actual costing",
                    "Process costing",
                    "A mix of job-costing and process costing"
                ],
                answer: "A mix of job-costing and process costing"
            },
            {
                question: "For a given job the direct costs associated with the job are:",
                options: [
                    "Actual overhead",
                    "Direct material",
                    "Direct manufacturing labor",
                    "Both b and c are correct."
                ],
                answer: "Both b and c are correct."
            },
            {
                question: "Copley Enterprises manufactures digital video equipment. For each unit $1475 of direct material is used and there is $1500 of direct manufacturing labor at $30 per hour. Manufacturing overhead is applied at $35 per direct manufacturing labor hour. Calculate the cost of each unit.",
                options: [
                    "$2975",
                    "$4025",
                    "$4725",
                    "$3150"
                ],
                answer: "$4725"
            },
            {
                question: "In a job-costing system a manufacturing firm typically uses an indirect-cost rate to estimate the ________ allocated to a job.",
                options: [
                    "Direct materials",
                    "Direct labor",
                    "Manufacturing overhead costs",
                    "Total costs"
                ],
                answer: "Manufacturing overhead costs"
            },
            {
                question: "The budgeted indirect-cost rate is calculated:",
                options: [
                    "At the beginning of the year",
                    "During the year",
                    "At the end of each quarter",
                    "At the end of the year"
                ],
                answer: "At the beginning of the year"
            },
            {
                question: "The difference between actual costing and normal costing is:",
                options: [
                    "Normal costing uses actual direct-cost rates",
                    "Actual costing uses actual quantities of direct-cost inputs",
                    "Normal costing uses budgeted indirect-cost rates",
                    "Actual costing uses actual quantities of cost-allocation bases"
                ],
                answer: "Normal costing uses budgeted indirect-cost rates"
            },
            {
                question: "Which of the following statements about normal costing is TRUE?",
                options: [
                    "Direct costs and indirect costs are traced using an actual rate.",
                    "Direct costs and indirect costs are traced using budgeted rates.",
                    "Direct costs are traced using a budgeted rate and indirect costs are allocated using an actual rate.",
                    "Direct costs are traced using an actual rate and indirect costs are allocated using a budgeted rate."
                ],
                answer: "Direct costs are traced using an actual rate and indirect costs are allocated using a budgeted rate."
            },
            {
                question: "When using a normal costing system manufacturing overhead is allocated using the ________ manufacturing overhead rate and the ________ quantity of the allocation base.",
                options: [
                    "Budgeted; actual",
                    "Budgeted; budgeted",
                    "Actual; budgeted",
                    "Actual; actual"
                ],
                answer: "Budgeted; actual"
            },
            {
                question: "When the allocated amount of indirect costs is less than the actual amount indirect costs have been:",
                options: [
                    "Overabsorbed",
                    "Underallocated",
                    "Under paid",
                    "Can’t be determined"
                ],
                answer: "Underallocated"
            },
            {
                question: "The simplest approach to dealing with underallocated or overallocated overhead is the ________ approach.",
                options: [
                    "Adjusted allocation-rate",
                    "Proration",
                    "Write-off to cost of goods sold",
                    "Both A and B are correct."
                ],
                answer: "Write-off to cost of goods sold"
            },
            {
                question: "The law firm of Smith & Jones has a staff of 30 lawyers and administrative staff. Budgeted total costs of the firm total $2100000 of which $1200000 is direct-labor costs. Assuming that the remaining costs are indirect and direct-labor cost is the allocation base calculate the budgeted indirect cost rate.",
                options: [
                    "57% of direct-labor cost",
                    "75% of direct-labor cost",
                    "43% of direct-labor cost",
                    "175% of direct-labor cost"
                ],
                answer: "75% of direct-labor cost"
            },
            {
                question: "For 20X5 Marcotte's Animal Supply Manufacturing uses machine-hours as the only overhead cost-allocation base. The accounting records contain the following information:\n\n\t\t\t\tEstimated\tActual\n\tManufacturing overhead costs\t$100000\t$120000\n\tMachine-hours\t\t20000\t25000\n\nUsing job costing the 20X5 budgeted manufacturing overhead rate is:",
                options: [
                    "$4.00 per machine-hour",
                    "$4.80 per machine-hour",
                    "$5.00 per machine-hour",
                    "$6.00 per machine-hour"
                ],
                answer: "$5.00 per machine-hour"
            },
            {
                question: "Based on the previous information using normal costing the amount of manufacturing overhead costs allocated to jobs during 20X5 is:",
                options: [
                    "$150000",
                    "$125000",
                    "$120000",
                    "$100000"
                ],
                answer: "$125000"
            },
            {
                question: "Costing systems that are used for the costing of like or similar units of products in mass production are called:",
                options: [
                    "Inventory-costing systems",
                    "Job-costing systems",
                    "Process-costing systems",
                    "Weighted-average costing systems"
                ],
                answer: "Process-costing systems"
            },
            {
                question: "Which of the following manufactured products would not use process costing?",
                options: [
                    "767 jet aircraft",
                    "19-inch television sets",
                    "Custom built houses",
                    "Both A and C are correct."
                ],
                answer: "Both A and C are correct."
            },
            {
                question: "Process costing should be used to assign costs to products when the:",
                options: [
                    "Units produced are similar",
                    "Units produced are dissimilar",
                    "Calculation of unit costs requires the averaging of unit costs over all units produced",
                    "Either A or C are correct."
                ],
                answer: "Either A or C are correct."
            },
            {
                question: "Conversion costs:",
                options: [
                    "Include all the factors of production",
                    "Include direct materials",
                    "In process costing are usually considered to be added evenly throughout the production process",
                    "Both B and C are correct."
                ],
                answer: "In process costing are usually considered to be added evenly throughout the production process"
            },
            {
                question: "The purpose of the equivalent-unit computation is to:",
                options: [
                    "Convert completed units into the amount of partially completed output units that could be made with that quantity of input",
                    "Assist the business in determining ending inventory",
                    "Convert partially completed units into the amount of completed output units that could be made with that quantity of input",
                    "Both B and C are correct."
                ],
                answer: "Convert partially completed units into the amount of completed output units that could be made with that quantity of input"
            },
            {
                question: "The weighted-average process-costing method calculates the equivalent units by:",
                options: [
                    "Considering only the work done during the current period",
                    "The units started during the current period minus the units in ending inventory",
                    "The units started during the current period plus the units in ending inventory",
                    "The equivalent units completed during the current period plus the equivalent units in ending inventory"
                ],
                answer: "The equivalent units completed during the current period plus the equivalent units in ending inventory"
            },
            {
                question: "Injection Molding Inc. manufactures plastic moldings for car seats. Its costing system utilizes two cost categories direct materials and conversion costs. Each product must pass through Department A and Department B. Direct materials are added at the beginning of production. Conversion costs are allocated evenly throughout production.\n\nData for Department A for February 20X5 are:\n\tWork in process beginning inventory 40% converted\t200 units\n\tUnits started during February\t\t\t600 units\n\tWork in process ending inventory 50% converted\t100 units\n\nCosts for Department A for February 20X5 are:\n\tWork in process beginning inventory:\n\tDirect materials\t\t\t$100000\n\tConversion costs\t\t\t$100000\n\tDirect materials costs added during February\t$1000000\n\tConversion costs added during February\t$1250000\n\nHow many units were completed and transferred out of Department A during February?",
                options: [
                    "100 units",
                    "600 units",
                    "700 units",
                    "800 units"
                ],
                answer: "700 units"
            },
            {
                question: "What is the unit cost per DM equivalent unit in Department A?",
                options: [
                    "$14257",
                    "$1750",
                    "$1375",
                    "$1250"
                ],
                answer: "$1375"
            },
            {
                question: "What is the unit cost per CC equivalent unit in Department A?",
                options: [
                    "$1800",
                    "$2014",
                    "$1375",
                    "$1250"
                ],
                answer: "$1800"
            },
            {
                question: "What is the unit cost per total equivalent unit in Department A?",
                options: [
                    "$30625",
                    "$3175",
                    "$2375",
                    "$1250"
                ],
                answer: "$3175"
            }
        ]
    }
];

export default chapters;