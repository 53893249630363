import React, { useState } from 'react';

const MCQTest = ({ questions, onBack }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [score, setScore] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [incorrectAnswers, setIncorrectAnswers] = useState([]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleNextQuestion = () => {
        const currentQuestion = questions[currentQuestionIndex];
        if (selectedOption === currentQuestion.answer) {
            setScore(score + 1);
        } else {
            setIncorrectAnswers([...incorrectAnswers, currentQuestion]);
        }
        setSelectedOption(null);
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            setShowScore(true);
        }
    };

    return (
        <div className="mcq-test">
            {showScore ? (
                <div>
                    <h2>Your Score: {score}/{questions.length}</h2>
                    <h3>Incorrect Answers:</h3>
                    <ul>
                        {incorrectAnswers.map((question, index) => (
                            <li key={index}>
                                <p>{question.question}</p>
                                <p><strong>Correct Answer:</strong> {question.answer}</p>
                            </li>
                        ))}
                    </ul>
                    <button onClick={onBack}>Back to Chapter Selection</button>
                </div>
            ) : (
                <div>
                    <h2>{questions[currentQuestionIndex].question}</h2>
                    {questions[currentQuestionIndex].options.map((option, index) => (
                        <div key={index} className="option">
                            <input
                                type="radio"
                                id={option}
                                name="mcq"
                                value={option}
                                checked={selectedOption === option}
                                onChange={handleOptionChange}
                            />
                            <label htmlFor={option}>{option}</label>
                        </div>
                    ))}
                    <button onClick={handleNextQuestion} disabled={!selectedOption}>
                        Next
                    </button>
                </div>
            )}
        </div>
    );
};

export default MCQTest;
