import React from "react";
import styled from "styled-components";
import boubyan from "../../../assets/images/boubyanHackathon.jpg";
import coded from "../../../assets/images/codedHackathon.jpg";
import kuwaitUni from "../../../assets/images/kuwaitUni.jpg";

const Prizes = (props) => {
  return (
    <Div>
      <div className="title">
        <h1>Hackathons & Prizes</h1>
      </div>

      <Project
        img={coded}
        title="Coded hackathon"
        desc="We participated in Coded hackathon 2019 and won the first prize, with an amazing team 😎"
      />

      <Project
        flip
        img={boubyan}
        title="Boubyan hackathon"
        desc="Won a hackathon at boubyan that required not only coding skills but also problem solving and innovation."
      />

      <Project
        flip
        img={kuwaitUni}
        title="Kuwait University projects contest"
        desc="Won the first prize for the best project, and got nominated to present Kuwait university In UAE, our project was a game❤️."
      />
    </Div>
  );
};

const Project = (props) => {
  return (
    <div className="project" data-aos={"fade-up"}>
      <img src={props.img} alt={props.title} />
      <div className="desc">
        <h2>{props.title}</h2>
        <h3>{props.desc}</h3>
      </div>
    </div>
  );
};

const Div = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    position: relative;
    align-text: center;
    opacity: 0.25;
    h1 {
      font-size: 128px;
      color: #131415;
      -webkit-text-fill-color: #131415; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }

  .project {
    background-color: white;
    overflow: hidden;
    border-radius: 16px;
    width: 460px;

    h2 {
      font-weight: bold;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
    .desc {
      padding: 12px 24px;
    }

    margin-bottom: 32px;
  }

  @media only screen and (max-width: 768px) {
    .title {
      h1 {
        font-size: 64px;
      }
    }
  }
`;

export default Prizes;
