import React from "react";
import { withGetScreen } from "react-getscreen";
import styled from "styled-components";
import { ReactComponent as AirShipImage } from "../../assets/images/airship.svg";
import { ReactComponent as CloudImage } from "../../assets/images/cloud.svg";

const Sky = (props) => {
  return (
    <Container>
      {[...Array(30)].map((_, i) => (
        <Cloud key={i} airship={i === 10} />
      ))}
    </Container>
  );
};

const Cloud = withGetScreen((props) => {
  let left = -150;

  function randomAttributes() {
    let cloudProps = {
      top: Math.random() * 1000,
      left: Math.random() * left,
      opacity: Math.abs(Math.random() - 0.4).toFixed(1),
      speed: (80 + Math.random() * 20).toFixed(0),
      scale: (Math.random() + 1).toFixed(1),
    };

    if (props.isMobile()) {
      cloudProps = {
        ...cloudProps,
        left: Math.random() * -100,
        speed: (30 + Math.random() * 20).toFixed(0),
      };
    }

    if (props.isTablet()) {
      cloudProps = {
        ...cloudProps,
        left: Math.random() * -100,
        speed: (30 + Math.random() * 20).toFixed(0),
      };
    }

    if (props.airship) {
      cloudProps = { ...cloudProps, opacity: 1 };
    }

    return cloudProps;
  }

  return (
    <AnimatedCloud attributes={randomAttributes()}>
      {props.airship ? <AirShipImage /> : <CloudImage />}
    </AnimatedCloud>
  );
});

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  pointer-events: none;

  .background {
    opacity: 0;
    position: fixed;
    z-index: 0;
    background: #131415;
    pointer-events: none;

    height: 100%;
    width: 100%;
  }
`;

const AnimatedCloud = styled.div`
  position: absolute;
  z-index: 0;
  height: 50px;
  width: 100%;
  opacity: ${(props) => props.attributes.opacity};
  left: ${(props) => props.attributes.left}%;
  top: ${(props) => props.attributes.top}px;
  transform: scale(${(props) => props.attributes.scale});
  animation: moving-cloud ${(props) => props.attributes.speed}s linear 0s
    infinite normal;
  svg {
    width: 40px;
    height: 100%;
  }

  @keyframes moving-cloud {
    from {
    }
    to {
      transform: translateX(${(props) => -1 * props.attributes.left + 100}%);
    }
  }
`;

export default Sky;
