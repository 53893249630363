import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import "./App.css";
import Home from "./containers/Home";

console.log(`
----------------------------------
|                                |
|                                |
|       Welcome My fellow        |
|           Developers           |
|                                |
|         You like the clouds?   |
|                                |
|    o o                         |
|   oooooo               ooo     |
|    oooo     oo       ooooo     |
|          oooooo       oo       |
|           ooo                  |
|                                |
|    I tried to make console     |
|       Clouds for you!          |
|                                |
|                                |
|                  IG:eng_menawer|
----------------------------------
`);
function App() {
  useEffect(() => {
    AOS.init({
      duration: 2000,
    });
  }, []);
  return <Home />;
}

export default App;
