import React from "react";
import styled from "styled-components";
import Cards from "../Cards";
import Footer from "../Footer";
import Sky from "../Sky";

const index = (props) => {
  return (
    <Container>
      <Sky />
      <Cards />
      <Footer />
    </Container>
  );
};

const Container = styled.div`
  height: 100%;

  @media only screen and (max-width: 768px) {
    padding: 0 5%;
  }
`;

export default index;
