import React from "react";
import styled from "styled-components";
import barmej from "../../../assets/images/barmej.jpg";
import boubyan from "../../../assets/images/boubyan.jpg";
import coded from "../../../assets/images/coded.jpg";
import punch from "../../../assets/images/punch.jpg";
import marzouq from "../../../assets/images/marzouq.jpg";
import majlis2020 from "../../../assets/images/majlis2020.jpg";
import fitnesswayq8 from "../../../assets/images/fitnesswayq8.jpg";
import uniqarn from "../../../assets/images/uniqarn.jpg";
import unicode from "../../../assets/images/unicode.jpg";
import ruba from "../../../assets/images/ruba.jpg";
import social from "../../../assets/images/social.jpg";

const Projects = (props) => {
  return (
    <Div>
      <div className="title">
        <h1>Stuff I've built</h1>
      </div>

      <Project
        img={ruba}
        title="Ruba - Co-Founded & CTO"
        desc="Rubapay Assist your child's tuition, Monthly installments, zero cost. Apply for tuition financing with RUBA now!"
        link="https://findruba.com"
      />

      <Project
        img={social}
        title="On Social Media"
        desc="On my free time i create videos about the world of development on Instagram & Youtube"
      />

      <Project
        img={boubyan}
        title="Boubyan"
        desc="Worked as a Frontend Techlead, Leading the frontend side of few projects including Boubyan Bank website, Corporate portal, Msa3ed backeoffice, Was an amazing 4 years, I learned alot. Thanks Boubyan 🚀"
        link="https://boubyan.bankboubyan.com/en/"
      />

      <Project
        img={unicode}
        title="Unicode - Instructor"
        desc="A CODED initiative for university students, Where i taught web basics."
      />

      <Project
        img={uniqarn}
        title="Uniqarn - Co-Founded"
        desc="Uniqarn is Kuwait’s first and only startup directory,The Kuwaiti Startup ecosystem is growing. There are a lot of Startups in Kuwait already, but all are scattered. We’ve created Uniqarn to host everything related to the Kuwaiti Startup ecosystem all in one place."
        link="https://uniqarn.com"
      />

      {/* <Project
        img={marzouq}
        title="Marzouq"
        desc="Freelanced: Marzouq is an application that helps Marzouq AlGhanim reach his supporters through News, Questions and Appointments."
      /> */}

      <Project
        img={punch}
        title="Punch"
        desc="Freelanced:Punch is a loyalty platform that allows customers to earn punch points, explore promotions, and redeem rewards."
        link="https://punchkw.com/"
      />

      <Project
        flip
        img={majlis2020}
        title="Majlis 2020"
        desc="An easy way for Kuwaiti electors to see the candidates, their name, bio and more, They can even reach them via questions."
        link="https://www.instagram.com/almajlis_kuwait/"
      />

      <Project
        img={coded}
        title="Coded"
        desc="Worked as an instructor in Coded for the Kuwait Codes initiative, an intiative that cover the whole country."
        link="https://joincoded.com/"
      />

      <Project
        flip
        img={barmej}
        title="Barmej"
        desc="I have worked with Barmej, an amazing startup that provide high quality coding courses, we worked on a Laravel course."
        link="https://barmej.com/"
      />

      <Project
        flip
        img={fitnesswayq8}
        title="FitnesswayQ8"
        desc="Freelanced: Developed a system to manage diet subscriptions, while also providing a channel for the customers to manage their subscripions."
        link="https://www.fitnesswayq8.com/"
      />
    </Div>
  );
};

const Project = (props) => {
  return (
    <div className="project" data-aos={"fade-up"}>
      <img src={props.img} alt={props.title} />
      <div className="desc">
        <h2>{props.title}</h2>
        <h3>{props.desc}</h3>
        {props.link && (
          <a target="_blank" href={props.link}>
            Learn more.
          </a>
        )}
      </div>
    </div>
  );
};

const Div = styled.div`
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  .title {
    position: relative;
    align-text: center;
    opacity: 0.25;
    h1 {
      font-size: 128px;
      color: #131415;
      -webkit-text-fill-color: #131415; /* Will override color (regardless of order) */
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: white;
    }
  }

  .project {
    overflow: hidden;
    border-radius: 16px;
    width: 460px;

    h2 {
      font-weight: bold;
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
    }
    .desc {
      margin-top: -4px;
      background-color: white;
      padding: 12px 24px;
      height: 100%;
    }
    a {
      font-size: 16px;
      font-weight: bold;
      color: #313131;
    }

    margin-bottom: 32px;
  }

  @media only screen and (max-width: 768px) {
    .title {
      h1 {
        font-size: 64px;
      }
    }
  }
`;

export default Projects;
