import React from "react";
import styled from "styled-components";

const index = (props) => {
  return (
    <Footer>
      Designed by the amazing{" "}
      <a href="https://www.instagram.com/taha.fakhruddin/">Taha Fakhruddin</a>
    </Footer>
  );
};

const Footer = styled.footer`
  color: white;
  font-size: 12px;
  text-align: center;
  opacity: 0.4;
  a {
    cursor: pointer;
    color: white;
  }
`;

export default index;
