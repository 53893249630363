import React from "react";
import styled from "styled-components";
import Experience from "./Card/Experience";
import Languages from "./Card/Languages";
import Prizes from "./Card/Prizes";
import Projects from "./Card/Projects";
import MCQ from "../MCQ";

const index = (props) => {

  const hideComponent = window.location.href.includes('/law');


  return (
    <Container>

      {hideComponent ? <MCQ /> : <>
        <div className="card" data-aos="fade-in">
          <Experience />
        </div>
        <div className="card" data-aos="fade-up">
          <Languages />
        </div>

        <Projects />

        <Prizes />
      </>
      }
    </Container>
  );
};

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;

  .card {
    margin-top: 80px;
    background-color: white;
    border-radius: 16px;
    padding: 80px;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 32px;
  }

  h2 {
    margin: 16px 0;
    font-size: 20px;
    font-weight: normal;
  }

  h3 {
    font-size: 16px;
    font-weight: normal;
    margin: 16px 0;
  }

  p {
    font-size: 14px;
  }

  @media only screen and (max-width: 768px) {
    .card {
      padding: 24px;
    }
  }
`;

export default index;
